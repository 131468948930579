import { useContext, useState } from 'react';

import enGB from 'date-fns/locale/en-GB';
import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import useForm from '../hooks/useForm';
import useValidateDate from '../hooks/useValidateDate';
import { Context } from '../Store';

registerLocale('en-GB', enGB);

const SearchTopHeader = ({ entity }) => {
  const [state] = useContext(Context);

  const [
    values,
    handleChange,
    handleDateChange,
    handleSelectChange,
    handleSubmit,
    handleReset,
    initialDates,
  ] = useForm();

  return (
    <>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <div className="mb-2" id="search-more-options">
          <div className="row">
            <div className="">
              <div className="d-flex mb-3 align-items-center">
                <strong className="me-5 ">Date</strong>
                <label htmlFor="dateFrom" className="d-block me-3 ">
                  From:
                </label>
                <div className="me-5">
                  <DatePicker
                    selected={values['dateFrom']}
                    onChange={(date) => handleDateChange({ name: 'dateFrom', value: date })}
                    selectsStart
                    startDate={values['dateFrom']}
                    endDate={values['dateTo']}
                    name="dateFrom"
                    minDate={initialDates.dateFrom}
                    maxDate={initialDates.dateTo}
                    dateFormat="PP"
                    locale="en-GB"
                    showYearDropdown
                    value={values['dateFrom']}
                  />
                </div>
                <label htmlFor="dateFrom" className="d-block me-3 ">
                  To:
                </label>

                <div>
                  <DatePicker
                    selected={values['dateTo']}
                    onChange={(date) => handleDateChange({ name: 'dateTo', value: date })}
                    selectsEnd
                    startDate={values['dateFrom']}
                    endDate={values['dateTo']}
                    minDate={values['dateFrom']}
                    maxDate={initialDates.dateTo}
                    dateFormat="PP"
                    locale="en-GB"
                    showYearDropdown
                    name="dateTo"
                    value={values['dateTo']}
                  />
                </div>
                <button className="btn btn-sm btn-secondary ms-2" type="submit">
                  Search
                </button>
                <button className="btn btn-sm btn-primary ms-2" type="reset">
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default SearchTopHeader;
