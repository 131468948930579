const CinemaSummary = ({ cinema }) => {
  return (
    <table className="table table-striped">
      <thead></thead>
      <tbody>
        <tr>
          <th>Name</th>
          <td>{cinema.name}</td>
        </tr>
        <tr>
          <th>Street</th>
          <td>{cinema.address.street}</td>
        </tr>
        <tr>
          <th>City</th>
          <td>
            {cinema.address.city.displayName} ({cinema.address.city.district})
          </td>
        </tr>
        <tr>
          <th>Location</th>
          <td>{cinema.venueLocation}</td>
        </tr>
        <tr>
          <th>Country</th>
          <td>{cinema.address.city.country}</td>
        </tr>
        <tr>
          <th>Seating Range</th>
          <td>{cinema.seatingRange}</td>
        </tr>
        <tr>
          <th>Venue Rank</th>
          <td>{cinema.venueRank}</td>
        </tr>
        <tr>
          <th>Turnover</th>
          <td>{cinema.turnover}</td>
        </tr>
        <tr>
          <th>Price Proxy</th>
          <td>
            {cinema.priceProxy &&
              parseFloat(cinema.priceProxy).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
          </td>
        </tr>
        <tr>
          <th>Cinema Status Index</th>
          <td>
            {cinema.revenuePotential &&
              parseFloat(cinema.revenuePotential).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
          </td>
        </tr>
        <tr>
          <th>Average Cinema Status Index</th>
          <td>
            {cinema.averageRevenuePotential &&
              parseFloat(cinema.averageRevenuePotential).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
          </td>
        </tr>
        <tr>
          <th>Cinema Weight</th>
          <td>
            {cinema.cinemaWeight &&
              parseFloat(cinema.cinemaWeight).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
          </td>
        </tr>
        <tr>
          <th>Active Cinema Weight</th>
          <td>
            {cinema.activeCinemaWeight &&
              parseFloat(cinema.activeCinemaWeight).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
          </td>
        </tr>
        <tr>
          <th>Opening Date</th>
          <td>{cinema.openingDate}</td>
        </tr>
        <tr>
          <th>Closing Date</th>
          <td>{cinema.closingDate}</td>
        </tr>
        <tr>
          <th>Cinema Status</th>
          <td>{cinema.status}</td>
        </tr>
        {cinema.manager1 && (
          <tr>
            <th>Manager</th>
            <td>{cinema.manager1}</td>
          </tr>
        )}
        {cinema.manger2 && (
          <tr>
            <th>Manager (additional)</th>
            <td>{cinema.manger2}</td>
          </tr>
        )}
        {cinema.venuePersons.length > 0 && (
          <tr>
            <th>Cinema People</th>
            <td>
              {cinema.venuePersons.map((person, index) => (
                <div key={index}>
                  {[
                    person.firstName && person.lastName
                      ? `Person: ${person.firstName} ${person.lastName}`
                      : null,
                    person.company ? `Company: ${person.company}` : null,
                    person.function ? `Function: ${person.function}` : null,
                  ]
                    .filter(Boolean)
                    .join(', ')}
                </div>
              ))}
            </td>
          </tr>
        )}
        {cinema.furtherInformation && (
          <tr>
            <th>Further Information</th>
            <td>{cinema.furtherInformation}</td>
          </tr>
        )}
        {cinema.FurtherInformationFr && (
          <tr>
            <th>Further Information (French)</th>
            <td>{cinema.FurtherInformationFr}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
export default CinemaSummary;
